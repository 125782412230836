.card {
  height: 350px;
  width: 400px;
  margin: 0 20px 0px;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .card {
    text-align: center;
  }
}
