.color_nav {
  background-color: #222222;
  color: #9d9d9d;
}

.list_align {
  list-style-type: none;
  display: flex;
  justify-content: center;
}

ul li {
  display: list-item;
  padding: 5px 10px;
}
